<template>
  <div>
    <div class="rounded p-3 mb-3" v-background-3>
      <h4>Oritentations stratégiques</h4>
      <uploader
        :id="'document_strategique'"
        url="/upload/document"
        file_system="CSE/ORIENTATIONS"
        file_field="rapport"
        :file_key="1"
        title="Liste des fichiers"
      ></uploader>
    </div>
  </div>
</template>
<script>
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: { Uploader },
  methods: {},
  mounted() {},
  computed: {},
};
</script>
